<template>
  <div class="page-products-list">
    <back />
    <div class="category">
      <div class="container">
        <n-loader :loading="$var('loadCategory')" />

        <div v-if="category.parent" class="title">{{ category.parent.title }}</div>
        <h2 v-if="category.title">{{ category.title }}</h2>
        <h2 v-else-if="!$var('loadCategory')">Все категории</h2>

        <n-button color="primary test">
          <n-link :to="{ name: 'categories.list' }">Выбрать категорию</n-link>
        </n-button>

        <sort :is-active.sync="isActive" @filter="load" />
      </div>
    </div>

    <div class="container">
      <h3>Топ товаров</h3>
    </div>
    <c-products-list-top :data="topProducts" :loading="$var('loadTop')" />

    <div class="container">
      <h3>Витрина товаров</h3>
    </div>
    <div class="container">
      <c-products-list :data="products" :loading="$var('load')" :wide="isActive" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageProductsList',
  data() {
    return {
      category: {},
      products: [],
      topProducts: [],
      isActive: false,
    }
  },
  computed: {
    categoryId() {
      return this.$route.query.categoryId
    },
  },
  created() {
    this.loadCategory()
    this.load()
    this.loadTop()
  },
  methods: {
    loadCategory() {
      this.$var('loadCategory', true)
      $api.app.categories.get(this.categoryId).with('parent').then((response) => {
        this.category = response.data.content
      }).finally(() => {
        this.$var('loadCategory', false)
      })
    },
    load(filter = (v) => v) {
      this.$var('load', true)
      filter($api.app.products.get()).filter({ categoryId: this.categoryId, }).then((response) => {
        this.products = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadTop() {
      this.$var('loadTop', true)
      $api.app.products.get().filter({ categoryId: this.categoryId, }).sort('salesCount', 'desc').size(10).then((response) => {
        this.topProducts = response.data.content
      }).finally(() => {
        this.$var('loadTop', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-products-list {
  .category {
    padding: 25px 0 15px;
    position: relative;

    .title {
      font-size: 12px;
      color: #808080;
    }

    .n-button {
      margin: 10px 0;

      a {
        color: #fff;
        font-size: 12px;
      }
    }
  }

  .test{
    background-color: #ECECEC;
    border-color: #ececec;
    padding: 4px 10px;
    color: #000;

    a{
      color: #000 !important;
    }
  }
}
</style>

<style>
.swiper-scrollbar {
  background: transparent;
}

.swiper-scrollbar-drag {
  background: #4BB222;
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: relative;
  margin-top: 16px;
}
</style>
